<template>
  <div v-if="this.$store.state.user.role.startsWith('management')">
    <div class="pa-4">

      <v-tabs
        v-model="tab"
        center-active
        fixed-tabs
        show-arrows
      >
        <v-tab
          v-for="notification in notifications"
          :key="notification.id"
          :href="'#' + notification.id"
        >
          お知らせ {{ notification.id }}
        </v-tab>
      </v-tabs>

      <v-tabs-items v-model="tab">
        <v-tab-item
          v-for="notification in notifications"
          :key="notification.id"
          :value="notification.id"
        >
          <v-card
            class="mx-auto mt-4 mb-1 pa-4"
            max-width="99%"
            elevation="3"
          >
            <v-card-title>お知らせ {{ notification.id }}</v-card-title>

            <!-- プレビュー -->
            <div>
              <v-row class="align-center mt-2 ml-10 mr-10 mb-4 rounded-lg" style="background-color: rgb(250, 250, 250);">
                <v-col cols="12" sm="2">
                  <v-card-subtitle class="pt-0 pb-0">プレビュー</v-card-subtitle>
                </v-col>
                <v-col cols="12" sm="8" class="pre-use-parts">
                  <v-banner
                    :sticky="true"
                    :single-line="false"
                    elevation="0"
                    width="480"
                    color="#ffffff"
                  >
                    <v-avatar
                      slot="icon"
                      :color="notification.icon_color ? notification.icon_color : 'green'"
                      size="40"
                    >
                      <v-icon color="white">
                        {{notification.icon_type ? notification.icon_type : 'assignment'}}
                      </v-icon>
                    </v-avatar>
                    <div class="created">掲載日：{{ t2s(notification.start_date) }}</div>
                    <h3 class="title">{{ notification.title }}</h3>
                    <div style="white-space:pre-wrap; word-break:break-all;">{{ notification.message }}</div>
                  </v-banner>
                </v-col>
                <v-col cols="12" sm="2">
                  <span v-if="notification.display_position === 0" class="red--text">（非表示）</span>
                  <span v-else-if="!isDuringThePublishing" class="red--text">（掲載期間外）</span>
                </v-col>
              </v-row> 
            </div>

            <!-- フォーム -->
            <v-card-text>
              <v-form 
                :ref="'form_' + notification.id"
                v-model="valid[notification.id]"
              >

                <!-- 件名 -->
                <v-text-field
                  outlined
                  label="件名（必須）"
                  v-model="notification.title"
                  :rules="requiredRules"
                  dense
                />

                <!-- 本文 -->
                <v-textarea
                  label="本文（必須）"
                  outlined
                  auto-grow
                  v-model="notification.message" 
                  :rules="requiredRules"
                  dense
                  rows=3
                />

                <!-- 対象医療機関 -->
                <v-radio-group
                  v-model="notification.is_select_all_customers"
                  label="対象医療機関"
                  row
                  class="mt-0"

                >
                  <v-radio key="all" label="全ての医療機関" :value="true" ></v-radio>
                  <v-radio key="select" label="医療機関を選択" :value="false"></v-radio>
                </v-radio-group>

                <!-- 医療機関選択 -->
                <v-select
                  v-if="!notification.is_select_all_customers"
                  v-model="notification.selected_customers"
                  :items="$store.state.customerOrgs"
                  :menu-props="{ maxHeight: '300' }"
                  label="医療機関を選択してください。（必須）"
                  multiple
                  item-text="name"
                  item-value="id"
                  :disabled="notification.is_select_all_customers"
                  :rules="requiredRules"
                  outlined
                  dense
                >
                  <template v-slot:prepend-item>
                    <v-list-item
                      ripple
                      @mousedown.prevent
                      @click="toggleAllSelectCoutomers()"
                    >
                      <v-list-item-action>
                        <v-icon :color="notification.selected_customers.length > 0 ? 'indigo darken-4' : ''">
                          {{ selectAllIcon }}
                        </v-icon>
                      </v-list-item-action>
                      <v-list-item-content>
                        <v-list-item-title>
                          全て選択
                        </v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                    <v-divider class="mt-2"></v-divider>
                  </template>
                </v-select>
               
                <!-- 掲載期間 -->
                <v-menu
                  v-model="menu[notification.id]"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      :value="dateRangeText"
                      label="掲載期間（必須）"
                      prepend-icon="mdi-calendar"
                      v-bind="attrs"
                      v-on="on"
                      readonly
                      clearable
                      :rules="requiredRules"
                      @click:clear="
                        notification.start_date = null;
                        notification.end_date = null;
                      "
                      outlined
                      class="mt-2"
                      dense
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="dates[notification.id]"
                    locale="jp-ja"
                    :day-format="date => new Date(date).getDate()"
                    no-title
                    scrollable
                    range
                    @input="validateDates()"
                  />
                </v-menu>

                <!-- アイコン選択 -->
                <legend style="font-size: 14px; color: rgba(0, 0, 0, 0.6);">表示するアイコン</legend>
                <v-radio-group
                  v-model="notification.icon_type"
                  label="種類"
                  row
                  hide-details
                  class="mt-0 ml-6"
                >
                  <v-radio 
                    v-for="icon in icons"
                    :key="icon"
                    :value="icon"
                    class="ml-3 mr-3"
                  >
                    <template v-slot:label>
                      <v-avatar
                        slot="icon"
                        :color="notification.icon_color"
                        size="40"
                      >
                        <v-icon color="white">{{ icon }}</v-icon>
                      </v-avatar>
                    </template>
                  </v-radio>
                </v-radio-group>

                <!-- アイコンの色選択 -->
                <v-radio-group
                  v-model="notification.icon_color"
                  label="色"
                  row
                  class="mt-2 ml-6"
                >
                  <v-radio key="green" value="green" class="ml-6 mr-6">
                    <template v-slot:label>
                        <span class="pt-1" style="color: green">緑</span>
                    </template>
                  </v-radio>
                  <v-radio key="yellow" value="yellow" class="mr-6">
                    <template v-slot:label>
                      <div style="background-color: rgb(102, 102, 102); width: 24px; height: 24px; text-align: center; padding: 2px 0px 0px;">
                        <span style="color: yellow;font-weight: bold;vertical-align: middle;">黄</span>
                      </div>
                    </template>
                  </v-radio>
                  <v-radio key="red" value="red" class="mr-6">
                    <template v-slot:label>
                      <span class="pt-1" style="color: red">赤</span>
                    </template>
                  </v-radio>
                  <v-radio key="#2196F3" value="#2196F3" class="mr-6">
                    <template v-slot:label>
                      <span class="pt-1" style="color: #2196F3">青</span>
                    </template>
                  </v-radio>
                </v-radio-group>

                <!-- 表示位置 -->
                <v-select
                  label="表示位置（必須）"
                  v-model="notification.display_position"
                  :items="displayPsitions"
                  :rules="requiredRules"
                  outlined
                  class="mt-3"
                  dense
                  @change="$refs[`form_${tab}`][0].validate()"
                >
                </v-select>

              </v-form>
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="darken-1" text @click="reset()">
                クリア
              </v-btn>
              <v-btn color="warning darken-1" text @click="save()" :disabled="!valid[notification.id]">
                保存する
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-tab-item>
      </v-tabs-items>
    </div>
  </div>
</template>

<script>
import dateformat from 'dateformat';

export default {
  name: 'notificationsManagement',
  components: {
  },
  data () {
    return {
      tab: null,
      notifications: [],
      notifications2: [],
      initialNotifications: [],
      ids: {},
      valid: {},
      isSelectAllCustomers: true,
      menu: {},
      dates: {},
      icons: ['assignment', 'local_shipping', 'show_chart', 'notification_important', 'warning', 'mdi-gift'],
      displayPsitions: [
        {value: 0, text: '非表示' },
        {value: 1, text: 'No.1' },
        {value: 2, text: 'No.2' },
        {value: 3, text: 'No.3' },
        {value: 4, text: 'No.4' },
        {value: 5, text: 'No.5' },
      ],
      requiredRules: [
        v => {
              const notification = this.notifications[this.ids[this.tab]];
              if (notification.display_position === 0) return true;
              if (!v && v !== 0) return '入力は必須です。';
              v = String(v);
              if (v.trim() === '') return '入力は必須です。';
              return true;
            },
      ],
      resetValues: {
        display_position: 0,
        end_date: null,
        icon_color: 'green',
        icon_type: 'assignment',
        is_select_all_customers: true,
        message: null,
        selected_customers: [],
        start_date: null,
        title: null,
      }
    }
  },
  computed: {
    likesAllCustomer () {
      if (!this.tab) return false;
      return this.notifications[this.ids[this.tab]].selected_customers.length === this.$store.state.customerOrgs.length
    },
    likesSomeCustomer () {
      if (!this.tab) return false;
      return this.notifications[this.ids[this.tab]].selected_customers.length > 0 && !this.likesAllCustomer
    },
    selectAllIcon () {
      if (this.likesAllCustomer) return 'mdi-close-box'
      if (this.likesSomeCustomer) return 'mdi-minus-box'
      return 'mdi-checkbox-blank-outline'
    },
    dateRangeText () {
      if (!this.tab) return '';
      const start = this.notifications[this.ids[this.tab]].start_date 
        ? dateformat(new Date(this.notifications[this.ids[this.tab]].start_date.seconds * 1000), 'yyyy-mm-dd')
        : '';
      const end = this.notifications[this.ids[this.tab]].end_date 
        ? dateformat(new Date(this.notifications[this.ids[this.tab]].end_date.seconds * 1000), 'yyyy-mm-dd')
        : '';   
      if (start == '' && end == '') return '';
      return `${start} 〜 ${end}`;
    },
    isDuringThePublishing () {
      const notification = this.notifications[this.ids[this.tab]];
      if (!notification) return false;

      const now = new Date();

      if (notification.start_date) {
        // 開始日時のチェック
        const startDate = new Date(notification.start_date.seconds * 1000);
        if (now < startDate) return false;
      }
      if (notification.end_date) {
        // 終了日時のチェック
        const endDate = new Date(notification.end_date.seconds * 1000);
        endDate.setDate(endDate.getDate() + 1);
        if (endDate <= now) return false;
      }
      return true;
    },
  },
  methods: {
    toggleAllSelectCoutomers () {
      this.$nextTick(() => {
        const notification = this.notifications[this.ids[this.tab]];
        if (this.likesAllCustomer) {
          notification.selected_customers = []
        } else {
          let tmp = [];
          for (const customerOrg of this.$store.state.customerOrgs) {
            tmp.push(customerOrg.id);
          }
          notification.selected_customers = tmp;
        }
      });
    },
    validateDates() {
      const dates = this.dates[this.tab];
      if (new Date(dates[1]).getTime() - new Date(dates[0]).getTime() < 0) dates.reverse();  // 逆転したときはリバースする
      // FirestoreのTimestamp型で保存する
      const start = !dates[0] || dates[0].trim() == '' 
        ? null 
        : this.$firebase.firestore.Timestamp.fromDate(new Date(dates[0].trim() + 'T00:00:00+09:00'));
      const end = !dates[1] || dates[1].trim() == '' 
        ? null 
        : this.$firebase.firestore.Timestamp.fromDate(new Date(dates[1].trim() + 'T00:00:00+09:00'));
      this.notifications[this.ids[this.tab]].start_date = start;
      this.notifications[this.ids[this.tab]].end_date = end;
    },
    async save() {
      // 入力エラーがある場合は終了
      if (!this.$refs[`form_${this.tab}`][0].validate()) return;
      const notification = this.notifications[this.ids[this.tab]];
      // 保存確認
      let msgs = [`【お知らせ${this.tab}】を保存してもよろしいですか？`];
      if (this.isDuringThePublishing && notification.display_position !== 0) {
        msgs = [
          `【お知らせ${this.tab}】を保存します。`,
          `保存後、すぐに公開されます。よろしいですか？`,
        ]
      }
      const result = await this.$root.$confirm_multi_line(`確認`, msgs);
      if (!result) return;
      try {
        // 更新
        notification.modified = this.$firebase.firestore.FieldValue.serverTimestamp();
        let ref = this.$db.collection('notifications_management').doc(this.tab);
        await ref.update(notification);
        await this.$root.$alert('成功', '保存が完了しました。');
        // 操作履歴
        this.$functions.log({
          tags: ['notifications_management', 'update'],
          message: `お知らせ設定：お知らせ${this.tab}：更新しました。`,
          item: { 
            before: this.initialNotifications[this.ids[this.tab]],
            after: notification,
          }
        });
        // 初期値を保管
        this.initialNotifications = JSON.parse(JSON.stringify(this.notifications));
      } catch (e) {
        await this.$root.$alert('エラー', '保存に失敗しました。' + e);
      }
    },
    reset() {
      this.notifications[this.ids[this.tab]] = Object.assign(this.notifications[this.ids[this.tab]], this.resetValues);
      this.dates[this.tab] = ['', ''];
      this.$refs[`form_${this.tab}`][0].validate();
    },
    t2s (t) {
      return t ? dateformat(new Date(t.seconds * 1000), 'yyyy/mm/dd'): ''; 
    },
  },
  async mounted() {
    const snapshot = await this.$db.collection('notifications_management').get();
    if (snapshot.empty) return;
    let i = 0;
    for (let doc of snapshot.docs) {
      if (!doc.exists) continue;
      const data = doc.data();
      data.id = doc.id;
      this.notifications.push(data);
      this.ids[doc.id] = i;
      i++;
    }

    // 初期値を保管
    this.initialNotifications = JSON.parse(JSON.stringify(this.notifications));
  },
  beforeDestroy() {
  },
}
</script>

<style>
  .theme--light.v-list-item.v-list-item--highlighted::before {
    opacity: 0.00;
  }

  .primary--text.theme--light.v-list-item.v-list-item--active.v-list-item--highlighted.v-list-item--link::before  {
    opacity: 0.12;
  }

  .theme--light.v-banner.v-sheet:not(.v-sheet--outlined):not(.v-sheet--shaped) .v-banner__wrapper {
    border-bottom: none !important;
  }
</style>

<style scoped>
  .pre-use-parts {
    display: flex;
    justify-content: center;
    padding-top: 15px;
    padding-bottom: 15px;
  }

  .v-sheet.v-banner {
    border-radius: 14px;
  }

  .pre-use-parts .title {
    font-weight: bold;
    margin: 0.75rem 0;
    line-height: 1.5rem;
    text-align: justify;
  }
  .pre-use-parts .created {
    color: gray;
  }
</style>